import React, { useState } from "react";
import "./LoginSignUp.css";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const LoginSignUp = () => {
  const [activeTab, setActiveTab] = useState("tabButton1");

  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="loginSignUpSection">
        <div className="loginSignUpContainer">
          <div className="loginSignUpTabs">
            <p
              onClick={() => handleTab("tabButton1")}
              className={activeTab === "tabButton1" ? "active" : ""}
            >
              Login
            </p>
            <p
              onClick={() => handleTab("tabButton2")}
              className={activeTab === "tabButton2" ? "active" : ""}
            >
              Register
            </p>
          </div>
          <div className="loginSignUpTabsContent">
            {/* tab1 */}

            {activeTab === "tabButton1" && <LoginForm handleTab={handleTab} />}

            {/* Tab2 */}

            {activeTab === "tabButton2" && (
              <RegisterForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignUp;
