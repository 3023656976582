import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoggedInCheck, loginUser } from "../../../Redux/authSlice";
import { Alert, CircularProgress } from "@mui/material";
import SnackbarComponent from "../../../Components/Snackbar";
import useSnackbar from "../../../hooks/useSnackbar";

function LoginForm({ handleTab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const { loading, error, isAuthenticated, logged } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(LoggedInCheck());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (logged === "pass" && isAuthenticated) {
      navigate("/shop");
    }
  }, [logged, isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(loginUser(data))
      .then(({ payload }) => {
        showSnackbar(
          payload.message || "Login successful!",
          payload.success ? "success" : "error"
        );
      })
      .catch((error) => {
        showSnackbar(error.message || "Login failed!", "error");
      });
  };

  return (
    <div className="loginSignUpTabsContentLogin">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="email"
          placeholder="Email address *"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Enter a valid email address",
            },
          })}
        />
        {errors.email && <p className="error-msg">{errors.email.message}</p>}

        <input
          type="password"
          placeholder="Password *"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          })}
        />
        {errors.password && (
          <p className="error-msg">{errors.password.message}</p>
        )}

        <div className="loginSignUpForgetPass">
          <label>
            <input
              type="checkbox"
              className="brandRadio"
              {...register("rememberMe")}
            />
            <p>Remember me</p>
          </label>
          <p>
            <Link to="/resetPassword">Lost password?</Link>
          </p>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
      </form>
      {error && (
        <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
          {error}
        </Alert>
      )}
      <div className="loginSignUpTabsContentLoginText">
        <p>
          No account yet?{" "}
          <span onClick={() => handleTab("tabButton2")}>Create Account</span>
        </p>
      </div>

      {loading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      )}

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
}

export default LoginForm;
