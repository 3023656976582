import React from 'react';
import Error from '../Components/Error/Error';

const NotFound = () => {
  return (
    <div>
      <Error />
    </div>
  );
};

export default NotFound;
