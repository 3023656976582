import React from 'react';
import TermsandConditions from '../Components/Terms/TermsandConditions';

const TermsConditions = () => {
  return (
    <div>
      <TermsandConditions />
    </div>
  );
};

export default TermsConditions;
