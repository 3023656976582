import axiosInstance from '../App/axiosInstance'

// Login user
export const login = async (credentials) => {
  const response = await axiosInstance.post('/auth/login', credentials);
  return response.data;
};

// Register user
export const register = async (userData) => {
  const response = await axiosInstance.post(`/auth/register`, userData);
  return response.data;
};

// IsLoggedIn user
export const isLoggedIn = async () => {
  const response = await axiosInstance.get(`/auth/isloggedin`);
  return response.data;
};

// Logout user
export const logout = async () => {
  const response = await axiosInstance.get(`/auth/logout`);
  return response.data;
};
