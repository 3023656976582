import React from 'react';
import CollectionBox from '../Components/Home/Collection/CollectionBox';
import Services from '../Components/Home/Services/Services';
import Trendy from '../Components/Home/Trendy/Trendy';
import DealTimer from '../Components/Home/Deal/DealTimer';

const Home = () => {
  return (
    <div>
      <CollectionBox />
      <Trendy />
      <DealTimer />
      {/* <Banner /> */}
      {/* <LimitedEdition /> */}
      {/* <Instagram /> */}
      <Services />
    </div>
  );
};

export default Home;
