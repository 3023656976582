import React from "react";
import { Link } from "react-router-dom";
import "./DealTimer.css";

const DealTimer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="mainDeal">
        <div className="dealTimer">
          <div className="dealTimerMainContent">
            <div className="dealTimeContent">
              <h3>
                <span>WELCOME TO </span>CHEF SET{" "}
                <span>
                  ONE OF THE UK’S LEADING MANUFACTURERS & SUPPLIERS OF CATERING
                  SUPPLIES.
                </span>
              </h3>

              <p>
                Choose From Top Quality aluminium, stainless steel or iron:
                chefs knives, cutlery, utensils, frying pans, jugs, wire ware,
                sauce pans, pots, gastronomes, dishes, consumables, reusables,
                tableware, cookware, bakeware & barware.
              </p>
              <p>
                Straight from the manufacturer delivered direct to your door, in
                England, Scotland & Wales. Please enquire with Sales Team for
                deliveries to Republic of Ireland & Northern Ireland.
              </p>
              <p>If you can’t find what your looking for please call us.</p>
              <p><a href="tel:+44(0) 121 356 6858"></a> +44(0) 121 356 6858</p>
              <div className="dealTimeLink">
                <Link to="/shop" onClick={scrollToTop}>
                  Shop Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DealTimer;
