import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoggedInCheck, registerUser } from "../../../Redux/authSlice";
import { Alert, CircularProgress } from "@mui/material";
import SnackbarComponent from "../../../Components/Snackbar";
import useSnackbar from "../../../hooks/useSnackbar";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const { loading, error, isAuthenticated, logged } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(LoggedInCheck());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (logged === "pass" && isAuthenticated) {
      navigate("/");
    }
  }, [logged, isAuthenticated, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(registerUser(data))
      .then(({ payload }) => {
        showSnackbar(
          payload.message || "Registered successful!",
          payload.success ? "success" : "error"
        );
      })
      .catch((error) => {
        showSnackbar(error.message || "Register failed!", "error");
      });
  };

  return (
    <div className="loginSignUpTabsContentRegister">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder="Username *"
          {...register("fName", {
            required: "Username is required",
          })}
          required
        />
        <input
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Enter a valid email address",
            },
          })}
          placeholder="Email address *"
          required
        />
        {errors.email && <p className="error-msg">{errors.email.message}</p>}

        <input
          type="password"
          placeholder="Password *"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long",
            },
          })}
        />
        {errors.password && (
          <p className="error-msg">{errors.password.message}</p>
        )}
        <p>
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our
          <Link
            to="/terms"
            style={{ textDecoration: "none", color: "#c32929" }}
          >
            {" "}
            privacy policy
          </Link>
          .
        </p>
        <button type="submit" disabled={loading}>
          {loading ? "Processing..." : "Register"}
        </button>
      </form>
      {error && (
        <Alert variant="filled" severity="error" style={{ marginTop: 20 }}>
          {error}
        </Alert>
      )}

      {loading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress />
        </div>
      )}

      <SnackbarComponent
        message={snackbar.message}
        severity={snackbar.severity}
        open={snackbar.open}
        onClose={hideSnackbar}
      />
    </div>
  );
}

export default LoginForm;
