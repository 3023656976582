import React from 'react';
import LoginSignUp from '../Components/Authentication/LoginSign/LoginSignUp';

const Authentication = () => {
  return (
    <div>
      <LoginSignUp />
    </div>
  );
};

export default Authentication;
