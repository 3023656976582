import React, { useEffect, useState } from "react";
import "./Trendy.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../Features/Cart/cartSlice";
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import { FaStar } from "react-icons/fa";
import { FaCartPlus } from "react-icons/fa";
import { fetchProductList } from "../../../Redux/productSlice";
import ProductSkeleton from "../../Skeleton/ProductSkeleton";

const Trendy = () => {
  const dispatch = useDispatch();

  const { products, loading } = useSelector((state) => state.product);

  const [activeTab, setActiveTab] = useState("tab1");

  useEffect(() => {
    const params = {
      limit: 8,
      skip: 0,
    };
    dispatch(fetchProductList(params));
  }, [dispatch]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [wishList, setWishList] = useState({});

  const handleWishlistClick = (productID) => {
    setWishList((prevWishlist) => ({
      ...prevWishlist,
      [productID]: !prevWishlist[productID],
    }));
  };

  return (
    <div>
      <div className="trendyProducts">
        <h2>
          Our Trendy <span>Products</span>
        </h2>
        <div className="trendyTabs">
          <div className="tabs">
            <p
              onClick={() => handleTabClick("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              All
            </p>
            <p
              onClick={() => handleTabClick("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              New Arrivals
            </p>
            <p
              onClick={() => handleTabClick("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              Best Seller
            </p>
            <p
              onClick={() => handleTabClick("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              Top Rated
            </p>
          </div>
          <div className="trendyTabContent">
            <div className="trendyMainContainer">
              {loading ? (
                <ProductSkeleton />
              ) : (
                products.map((product) => (
                  <div className="trendyProductContainer" key={product._id}>
                    <div className="trendyProductImages">
                      <Link to="/shop" onClick={scrollToTop}>
                        <img
                          src={product.images[0]?.src}
                          alt={product.title}
                          // className="trendyProduct_front"
                        />
                        {product?.images[1] && product?.images[1]?.src && (
                          <img
                            src={product.images[1]?.src}
                            alt={product.title}
                            className="sdProduct_back"
                          />
                        )}
                      </Link>
                      <h4 onClick={() => dispatch(addToCart(product))}>
                        Add to Cart
                      </h4>
                    </div>
                    <div
                      className="trendyProductImagesCart"
                      onClick={() => dispatch(addToCart(product))}
                    >
                      <FaCartPlus />
                    </div>

                    <div className="trendyProductInfo">
                      <div className="trendyProductCategoryWishlist">
                        <p>{product.product_type}</p>
                        <FiHeart
                          onClick={() => handleWishlistClick(product.id)}
                          style={{
                            color: wishList[product.id] ? "red" : "#767676",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className="trendyProductNameInfo">
                        <Link to="product" onClick={scrollToTop}>
                          <h5>{product.title}</h5>
                        </Link>

                        <p>£{product.variants[0].price}</p>
                        <div className="trendyProductRatingReviews">
                          <div className="trendyProductRatingStar">
                            <FaStar color="#FEC78A" size={10} />
                            <FaStar color="#FEC78A" size={10} />
                            <FaStar color="#FEC78A" size={10} />
                            <FaStar color="#FEC78A" size={10} />
                            <FaStar color="#FEC78A" size={10} />
                          </div>
                          <span>{product.productReviews || ""}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="discoverMore">
          <Link to="/shop" onClick={scrollToTop}>
            <p>Discover More</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Trendy;
