import React from 'react';
import ClearancePage from '../Components/ClearancePage/ClearancePage';

const Clearance = () => {
  return (
    <div>
      <ClearancePage />
    </div>
  );
};

export default Clearance;
