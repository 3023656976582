import axiosInstance from "../App/axiosInstance";

// product list
export const productListApi = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await axiosInstance.get(`/product?${queryString}`);
  return response.data;
};

// product type
export const productTypeApi = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await axiosInstance.get(`/product/product-type?${queryString}`);
  return response.data;
};
