import React from 'react';
import ShopDetails from '../Components/Shop/ShopDetails/ShopDetails';

const Shop = () => {
  return (
    <div>
      <ShopDetails />
    </div>
  );
};

export default Shop;
